<template>
  <ion-app>
    <ion-router-outlet />
    <ReloadPrompt />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import ReloadPrompt from "./components/ReloadPrompt.vue";
import { ref } from 'vue'
import { Network } from '@capacitor/network';
import { toastController } from '@ionic/vue';

const theStatus = ref({ connected: true });

const theToast = toastController.create({
  message: '網路連線中斷',
  cssClass: "custom-toast",
  position: 'middle',
});
Network.addListener('networkStatusChange', async (status) => {
  theStatus.value = status
  const toast = await theToast
  if (!theStatus.value.connected) {

    await toast.present();
  } else {
    await toast.dismiss();
  }
});

</script>
<style>
  ion-toast.custom-toast {
    font-size: 30px;
    text-align: center;
  }

</style>
