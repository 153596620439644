<script setup lang="ts">
import { IonAlert } from '@ionic/vue';
import { useRegisterSW } from 'virtual:pwa-register/vue'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    console.log('registered sw:', swUrl)
    r && setInterval(async () => {
      if (!(!r.installing && navigator)) return;

      if ('connection' in navigator && !navigator.onLine) return;

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          cache: 'no-store',
          'cache-control': 'no-cache',
        },
      });

      if (resp?.status === 200) {
        const result = await r.update()
        console.log('result', result)
      };
    }, 60 * 60 * 1000);
  },
})

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}

const alertButtons = [
  {
    text: 'Cancel',
    role: 'cancel',
    handler: () => {
      close()
    },
  },
  {
    text: 'OK',
    role: 'confirm',
    handler: () => {
      updateServiceWorker(true)
    },
  },
];
</script>

<template>
  <ion-alert :is-open="needRefresh" message="有新版本可用，是否現在更新？" @didDismiss="needRefresh = false" :buttons="alertButtons">
  </ion-alert>
</template>
